<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class='pb-5'>
          <div class="d-flex justify-content-between align-items-center px-4 pt-4">
            <h3 class="card-title font-weight-bold" style='font-size: 30px'>Categorias</h3>
            <vs-button @click="$router.push('NuevaCategoria')">Nueva Categoría</vs-button>
          </div>
          <div class="table-responsive px-4 mt-5">
            <table class="table table-bordered table-responsive-md table-striped text-center">
              <thead>
                <tr>
                  <th style='width: 9rem;'>
                    Nombre
                  </th>
                  <th>
                    Descripción
                  </th>
                  <th style='width: 5rem;'>
                    Opciones
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in saveCategoria" :key="item.id">
                  <td class='font-weight-bold'>
                    {{ item.nombre }}
                  </td>
                  <td>
                    {{ item.descripcion }}
                  </td>
                  <td>
                    <div class='d-flex'>
                      <vs-button @click="$router.push(`EditarCategoria/${item.id}`)"><ion-icon
                          name="create-outline"></ion-icon></vs-button>
                      <vs-button class='bg-danger' @click="deleteCategoria(item.id)"><ion-icon
                          name="trash-outline"></ion-icon></vs-button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import { core } from "../../../../../config/pluginInit";
  import Swal from 'sweetalert2';
  export default {
    data: () => ({
      saveCategoria: [],
      empresaId: null,
    }),
    async mounted() {
      const valor = JSON.parse(localStorage.getItem('setPersonaAct'));
      if (valor) {
        this.empresaId = valor.empresa.id;
      }

      core.index();
      await this.getData();
      window.$("#datatable_categoria").DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
        },
      });
    },
    methods: {
      async getData() {
        try {
          let res = await this.$store.dispatch("hl_get", {
            path: 'CONTCategoria/GetCategoriaByEmpresaId/' + this.empresaId,
          });

          if (res.data.length > 0) {
            this.saveCategoria = res.data.map(item => ({
              id: item.Id,
              nombre: item.Nombre,
              descripcion: item.Descripcion
            }));
          } else {
            this.saveCategoria = [];
          }

        } catch (error) {
          this.saveCategoria = [];
        }
      },
      async deleteCategoria(id) {
        await Swal.fire({
          title: '¿Estás seguro que deseas eliminar la Categoria?',
          text: "¡No podrás revertir esta acción!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, borrarlo!'
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              let response = await this.$store.dispatch("hl_delete", {
                path: `CONTCategoria/EliminarCategoria/${id}`,
              });
              console.log("sdasdasddfdg",response);
              if (response.respuesta) {
                await this.getData();
                Swal.fire("Listo!", "Categoria eliminada Correctamente", "success");
              } else if (response.respuesta == false) {
                Swal.fire("Error", "la categoria tiene articulos asociados", "error");
              }
            } catch (error) {
              Swal.fire("Error", "Hubo un problema al eliminar la Categoria", "error");
            }
          }
        });
      }
    }
  }
</script>

<style>

  .vs-table__header,
  .vs-input,
  .vs-table__footer,
  .vs-pagination__arrow,
  .vs-pagination__arrow {
    background: var(--body-bg) !important;
  }
</style>